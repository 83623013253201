.tenzies {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
    padding: 30px;

    @media screen and (max-width: 545px) {
        height: auto;
    }
    
    &__inner {
        background: #F5F5F5;
        color: #2B283A;
        border-radius: 10px;
        padding: 35px;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 515px;
        height: 545px;

        @media screen and (max-width: 545px) {
            width: 100%;
            height: auto;
            padding: 20px;
        }
    }
    
}

.dice {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.2rem;
    margin: 30px 0 0;

    @media screen and (max-width: 545px) {
        gap: 2rem;
    }

    @media screen and (max-width: 475px) {
        gap: 1.8rem;
    }

    @media screen and (max-width: 374px) {
        gap: 1rem;
    }
}

.roll-dice {
    background: #5035FF;
    color: #fff;;
    box-shadow: 0px 3.5px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    min-width: 180px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 700;
    border: none;
    padding: 15px 30px;
    margin: 30px 0 0;
    transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    -moz-transition: .25s;
    -webkit-transition: .25s;
    cursor: pointer;

    @media screen and (max-width: 545px) {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }

    &:hover {
        opacity: 0.75;
    }

    &:active {
        transform: scale(0.95);
    }
}