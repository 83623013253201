.tenzies-title, .tenzies-text, .tenzies-count  {
    margin: 15px 0 0;

    &:first-child {
        margin: 0;
    }
}

.tenzies-title {
    width: 100%;
    font-size: 2.5em;
    line-height: 3rem;

    @media screen and (max-width: 545px) {
        font-size: 2.2em;
        line-height: 2.7rem;
    }

    @media screen and (max-width: 374px) {
        font-size: 1.8em;
        line-height: 2.4rem;
    }

    span {
        color: #5035FF;
    }
}

.tenzies-text {
    width: 100%;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-family: 'Inter', sans-serif;

    @media screen and (max-width: 545px) {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

    @media screen and (max-width: 475px) {
        font-size: 1rem;
        line-height: 1.4rem;
    }

    @media screen and (max-width: 375px) {
        font-size: 0.9rem;
        line-height: 1.3rem;
    }
}

.tenzies-count {
    width: 100%;
    font-size: 1rem;
    line-height: 1.2rem;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    
    span {
        color: #5035FF;
    }
}